<template>
  <section id="activity" class="container topper py-4 text-start"> 
    <router-link to='/search' class="text-dark text-center">
      <h1 id='chachi-header' class="display-4"><mark>{{ $t('chachi') }}</mark><sup v-if="currentUser.proUser === true" class="pro-tag">PRO</sup></h1>
      <p style='margin-top: -0.5rem; margin-bottom: auto;'><small>Go back</small></p>
    </router-link>
    <hr/>
    <div id='activity-text' class='col px-4 py-4 br-16' :style="'background-color:' + this.cardPalette[this.currentCardIndex%this.cardPalette.length] + '80'">
      <!-- Main text -->
      <markdown-it-vue-light class="md-body" :content="cardContent" :options="mdoptions"/>
    </div>

    <ul class='list-unstyled text-center'>
      <!-- Meta information -->
      <li v-if="card.subject != '999'" class="meta px-1" id="card-subject">{{ card.subject }}</li>
      <li v-if="card.life_skill != '999'" class="meta px-1" id="card-life-skill">{{ card.life_skill }}</li>
      <li v-if="card.topic != '999'" class="meta px-1" id="card-topic">{{ card.topic }}</li>
      <li v-if="card.class_range != '999'" class="meta px-1" id="card-class-range">{{ card.class_range }}</li>
    </ul>
    <div class='col-sm-6 mx-auto text-center underline px-2 mt-2' tabindex="0" @click="usageSuggestion()" style="cursor: pointer">
      <router-link to='/how-to' class='text-muted' @click="plausibleEvent('howToUse')"> {{$t('activity-how-to-use')}} </router-link>
    </div>
    <div class='col-sm-6 mx-auto text-center px-2 mt-2' tabindex="0" @click="nextClicked()" style="cursor: pointer">Next</div>
    <!-- <div class='col-sm-6 mx-auto text-muted text-center underline px-2 mt-2' tabindex="0" @click="usageSuggestion()" style="cursor: pointer">{{$t('activity-how-to-use')}}</div> -->
    <!-- <div id='usage-suggestion' class='col-lg-9 mx-auto p-2 br-8 bg-beige text-muted d-none'></div> -->

    <user-toolbar :card="card"/>

    <!-- <router-link to='/search' class=''>Go back</router-link> -->

    <hr class='mt-5'/>
    <!-- <p class='text-center mt-2'><small class='text-danger' tabindex="0" @click="reportError()" style="cursor: pointer">Report error</small></p> -->

    <div id="commento" class='mt-5 d-none'></div>

  </section>
</template>

<script>
import { mapState } from 'vuex'
import UserToolbar from './UserToolbar.vue'
import axios from 'axios'
import MarkdownItVueLight from 'markdown-it-vue/dist/markdown-it-vue-light.umd.min.js'

export default {
  name: 'Activity',
  props: {
  },
  computed: {
      ...mapState({
      isLoggedIn: state => state.isLoggedIn,
      currentUser: state => state.currentUser,
      lang: state => state.lang,
      cards: state => state.cards,
      currentCardIndex: state => state.currentCardIndex,
      category: state => state.category,
      categoryValue: state => state.categoryValue
    })
  },
  data() {
    return {
      card: {},
      proUser: true,
      // cardPalette: ["#003f5c","#444e86","#955196","#dd5182","#ff6e54","#ffa600"],
      cardPalette: ["#c0d8c0", "#9ad6ed", "#e6b8b8", "#a7cac7", "#cdb4d1", "#a8b5c5"],
      // cardPalette: ["#edf2fb", "#e2eafcff", "#d7e3fcff", "#c1d3feff"],
      // cardPalette: ["#eff1db", '#ffd4db', '#d3b5e5', '#bbe7fe']
      mdoptions: {
        markdownIt: {
          html: true,
          linkify: true,
          breaks: true
        },
        linkAttributes: {
          attrs: {
            target: '_blank',
            rel: 'noopener nofollow'
          }
        },
      },
      cardContent: 'Loading...',
      // cardContent: '### How well do you know numbers?\n\nLook at this image –\n\n![](https://nrich.maths.org/content/97/12/six2/odd_sq.gif)\n<small>Source: NRICH</small>\nCan you see that it shows `@1 + 3 + 5 + ... + (2n - 1) = n^2@`? Try to figure it out!',
      // cardContent: "### Look at logos of McDonald’s and Audi.\n\n![](https://upload.wikimedia.org/wikipedia/commons/7/79/McdonaldMini.png)\n![](https://4.bp.blogspot.com/_nokDThCm0i8/Sk_JqzxrtAI/AAAAAAAABY4/GJ7nKRj-2IY/s400/audilogo.jpg)\n\nIs there any symmetry in them?\n\nCan you find other logos that are symmetrical? Do you think symmetrical logos are better than non-symmetrical ones in any way?\n\n|Samosa|2|\n|-|-|\n|Chocolates|3|\n|…|…|\n\nDo you have to pay for it? *Should you* have to pay for it?"
    }
  },
  components: {
    UserToolbar,
    MarkdownItVueLight
  },
  created() {
    // commento.js
    let commento_script = document.createElement('script')
    commento_script.defer = true
    commento_script.setAttribute('data-no-websockets', "true")
    commento_script.setAttribute('data-no-livereload', "true")
    commento_script.setAttribute('data-page-id', '/'+window.location.hash)
    commento_script.setAttribute('src', 'https://commento.chachi.app/js/commento.js')
    document.head.appendChild(commento_script)

    // commento count.js
    let commento_count_script = document.createElement('script')
    commento_count_script.setAttribute('src', 'https://commento.chachi.app/js/count.js')
    document.head.appendChild(commento_count_script)
  },
  mounted() {
    // add commento count
    var commentoCount = document.getElementById('commento-count')
    commentoCount.setAttribute('data-page-id', '/'+window.location.hash)

    var lang = this.$store.state.lang
    if (this.currentCardIndex > -1 && this.cards.length > 0 && this.cards[this.currentCardIndex].secret_id == this.$route.params.pathMatch) {
        // if coming from the search page
        this.card = this.cards[this.currentCardIndex]
        if (lang === 'en') {
          this.cardContent = this.card.en
        } else if (lang === 'hi') {
          this.cardContent = this.card.hi
        }
    } else {
        // if browsing directly to the URL
        var api_url = 'https://api.chachi.app/v2/bank/' + encodeURI(lang) + '/' + this.$route.params.pathMatch
        axios.get(api_url)
            .then( response => {
              if (response.data === 'timed out') {
                if (lang === 'en') {
                  this.cardContent = "It seems there was an error. Please try refreshing the page."
                } else if (lang === 'hi') {
                  this.cardContent = "लगता है कुछ तकनीकी खराबी आ गई है। कृपया पेज को रेफ़्रेश कर के देखें।" // eslint-disable-line
                }
              } else {
                this.card = response.data[0]
                if (lang === 'en') {
                  this.cardContent = this.card.en
                } else if (lang === 'hi') {
                  this.cardContent = this.card.hi
                }
              }
              // set bg color
              var pick = Math.floor(Math.random() * 4)
              document.getElementById('activity-text').style.backgroundColor = this.cardPalette[pick] + '80'
            })
            .catch( err => {
              console.log(err)
              if (lang === 'en') {
                this.cardContent = "It seems there was an error. Please try refreshing the page."
              } else if (lang === 'hi') {
                this.cardContent = "लगता है कुछ तकनीकी खराबी आ गई है। कृपया पेज को रेफ़्रेश कर के देखें।" // eslint-disable-line
              }
            })
    }

    // set variables and classes based on screen size
    if (window.innerWidth > 450) { 
      const el = document.getElementById('activity')
      el.classList.remove('br-16')
      el.classList.add('pt-5')
    }

    // set fullscreen height for smaller activities
    const mainHeight = document.getElementById('activity').offsetHeight
    if (mainHeight < 432) {
      document.getElementById('activity').classList.add('view-height')
    }
  },
  methods: {
    plausibleEvent(event) {
      this.$plausible.trackEvent(event)
    },
    usageSuggestion() {
      document.getElementById('usage-suggestion').classList.toggle('d-none')
    },
    nextClicked() {
      console.log(this.cards.length, this.currentCardIndex);
      if (this.currentCardIndex > -1 && this.cards.length > 0 && (this.cards.length - 2) !== this.currentCardIndex) {
          // if coming from the search page
          this.$store.commit('setCardIndex', this.currentCardIndex + 1);
          this.$router.push(`card-${this.cards[this.currentCardIndex + 1].secret_id}`);
      } else {
        if (this.category.length > 0) {
          var api_link = "https://api.chachi.app/v2/bank/" + encodeURI(this.lang) + '/random/' + encodeURI(this.category) + '/' + encodeURI(this.categoryValue)
        } else {
          api_link = "https://api.chachi.app/v2/bank/" + encodeURI(this.lang) + '/random'
        }
        // push more cards to the existing array
        axios.get(api_link)
          .then( response => {
            response.data.forEach(element => {
              this.$store.commit('addCards', element)
            });
            this.$store.commit('setCardIndex', this.currentCardIndex + 1);
            this.$router.push(`card-${this.cards[this.currentCardIndex + 1].secret_id}`);
          })
          .catch( err => console.log(err));
      }
    },
    howToUse() {
      if (this.lang === 'hi') {
        if (this.card.subject === 'Language') {
          var text = "This activity is well suited for building language and effective communication skills. It is worth exploring it in relation to a similar topic from language textbooks. It makes you think creatively and come up with imaginative ideas and builds an appreciation for the biological diversity in our surroundings."
          document.getElementById('usage-suggestion').textContent = text
        }
      }
    }
  },
}
</script>

<style scoped>
.meta {
  /* line-height: 1rem; */
  /* font-weight: 450; */
  font-variant-caps: all-petite-caps;
  font-size: 1em;
  color: rgb(69, 69, 69);
  display: inline-block;
}
.btn-interaction {
  width: 50px
}
.modal-footer {
  padding: 0;
}
.modal-header {
  padding-top: 0;
  padding-bottom: 0;
}
.view-height {
  height: 90vh;
}
.topper {
  width: 95%;
} 
@media screen and (min-width: 768px) {
  .topper {
    width: 50%;
    max-width: 700px;
  }
}
#chachi-header {
  font-family: 'Mukta';
  font-weight: bold;
}
.underline {
    text-decoration: underline;
}
</style>