<template>
    <nav id="user-interaction-toolbar" class="navbar navbar-light shadow-sm text-center fixed-bottom" style="background-color: #f4f2f0">
    <div class="container-fluid">
        <div id='toolbar-nav' class='row mx-auto'>
            <div class='col d-grid gap-2 nav-item' @click="plausibleEvent('bookmark')">
                <a v-if="!bookmarked" class="user-interaction-toolbar-link" role="button" tabindex="0" @click="toggleBookmark(1)"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16"><path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/></svg></a>
                <a v-if="bookmarked" class="user-interaction-toolbar-link" role="button" tabindex="0" @click="toggleBookmark(0)"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/></svg></a>
            </div>
            <div class='col d-grid gap-2 nav-item' @click="plausibleEvent('note')">
                <a v-if="proUser" class="user-interaction-toolbar-link" role="button" tabindex="0" @click="addNote()"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-journal" viewBox="0 0 16 16"><path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"/><path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"/></svg></a>
            </div>
            <div class='col d-grid gap-2 nav-item' @click="plausibleEvent('comment')">
                <a class="user-interaction-toolbar-link" role="button" tabindex="0" @click="showCommento">
                  <svg style="position: relative; right: -5px;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chat-dots" viewBox="0 0 16 16"><path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/><path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"/></svg>
                  <a href="#commento" id="commento-count" data-badge=""></a>
                </a>
            </div>
            <div class='col d-grid gap-2 nav-item dropup'>
                <a class="user-interaction-toolbar-link " tabindex="0" id="share-dropup" data-bs-toggle="dropdown" aria-expanded="false"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-share" viewBox="0 0 16 16"><path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/></svg></a>
                <ul class="dropdown-menu" aria-labelledby="share-dropup">
                    <!-- <li id='toolbar-share-to-fb' class="underline dropdown-item text-dark share-menu"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16"><path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/></svg> Facebook</li>
                    <li id='toolbar-share-to-tw' class="underline dropdown-item text-dark share-menu"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16"><path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/></svg> Twitter</li> -->
                    <li class="underline dropdown-item text-dark share-menu" @click="plausibleEvent('telegram')"><a id='toolbar-share-to-tg' href='' target="_blank" rel='noopener nofollow'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telegram" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/></svg> Telegram</a></li>
                    <li class="underline dropdown-item text-dark share-menu" @click="plausibleEvent('whatsapp')"><a id='toolbar-share-to-wa' href='' target="_blank" rel='noopener nofollow'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16"><path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/></svg> WhatsApp</a></li>
                    <li id='toolbar-copy-text' class="underline dropdown-item text-dark share-menu" @click="copyText(1)"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16"><path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/><path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/></svg> Copy</li>
                </ul>
            </div>
        </div>
    </div>
    </nav>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import $ from 'jquery'
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'

export default {
  name: 'usertoolbar',
  props: ["card"],
  computed: {
    ...mapState({
      isLoggedIn: state => state.isLoggedIn,
      currentUser: state => state.currentUser,
      lang: state => state.lang,
      cards: state => state.cards,
      currentCardIndex: state => state.currentCardIndex,
      // card: state => state.cards[state.currentCardIndex]
    }),
    bookmarked: {
        get: function() {
            if (this.isLoggedIn) {
              if (this.currentUser.bookmarks.indexOf(this.card.secret_id) > -1) { return true}
              else { return false }
            } else {
              return false
            }
        },
        set: function(newState) {
            return newState
        }
    },
  },
  data() {
    return {
      // card: {},
      proUser: true,
    }
  },
  components: {
  },
  mounted() {
    if (this.isLoggedIn) {
      // update sharing links
      if (this.lang === 'en') {
        var text = this.card.en
        text = text + '\n---\nThis activity comes from Chachi. Get hundreds more at https://chachi.app for free!'
      } else if (this.lang ==='hi') {
        text = this.card.hi
        text = text + '\n---\nयह गतिविधि चाची ऐप से आई है। ऐसी ढेरों और पाइए https://chachi.app पर बिलकुल मुफ़्त!'
      }
      document.getElementById('toolbar-share-to-wa').href = 'whatsapp://send?text=' + encodeURI(text)
      document.getElementById('toolbar-share-to-tg').href = 'https://t.me/share/url?url=' + encodeURI('https://chachi.app') + '&text=' + encodeURI(text)

      // if (this.currentCardIndex > -1 && this.cards.length > 0 && this.cards[this.currentCardIndex].secret_id == this.$route.params.pathMatch) {
      //     // if coming from the search page
      //     this.card = this.cards[this.currentCardIndex]
      // } else {
      //     // if browsing directly to the URL
      //     var api_url = 'https://api.chachi.app/v2/bank/en/' + this.$route.params.pathMatch
      //     axios.get(api_url)
      //         .then( response => this.card = response.data[0])
      //         .catch( err => console.log(err) )
      // }
    }
    var el = document.getElementById('user-interaction-toolbar')
    if (window.innerWidth > 450) {
      el.classList.remove('fixed-bottom')
      el.classList.add('mt-5')
    }
  },
  methods: {
    plausibleEvent(event) {
      this.$plausible.trackEvent(event)
    },
    toggleBookmark(bookstate) {
      if (this.isLoggedIn) {
        var payload = this.card.secret_id
        if (bookstate) {
            var api_link = 'https://api.chachi.app/v2/users/actions/bookmarks?user=' + encodeURI(this.currentUser.uid) + '&addbookmark=' + encodeURI(payload)
        } else {
            api_link = 'https://api.chachi.app/v2/users/actions/bookmarks?user=' + encodeURI(this.currentUser.uid) + '&delbookmark=' + encodeURI(payload)
        }
        const token = this.$cookies.get('user-jwt')
        const config = { headers: { "Authorization": `Bearer ${token}`, "Access-Control-Expose-Headers": "Authorization" } };
        axios.post(api_link, {}, config) // in post, third var is config
          .then ( response => {
            if (response.data.detail === "Invalid token or expired token.") {
              this.$store.dispatch('setUserJWT')
            }
            if (response.data.success) {
              // fetch latest user data; could update bookmarks here manually but that could potentially cause a mismatch maybe? phuket, we'll see
              // this.$store.dispatch("setCurrentUser")
              this.$store.dispatch("updateLocalUserBookmarks", [bookstate, payload])

              this.bookmarked = !this.bookmarked

              if (this.bookmarked === false) {
                var toast = new bootstrap.Toast($('#liveToastWarning'));
                var el = document.getElementById('toast-message-warning')
                if (this.lang === 'en') { el.textContent = "Removed from bookmarks!" }
                else if (this.lang === 'hi') { el.textContent = "कार्ड को बुक्मार्क से हटा दिया गया!" }
              } else {
                toast = new bootstrap.Toast($('#liveToastPrimary'));
                el = document.getElementById('toast-message-primary')
                if (this.lang === 'en') { el.textContent = "Saved to bookmarks!" }
                else if (this.lang === 'hi') { el.textContent = "बुक्मार्क कर लिया गया!" }
              }
            } else {
              toast = new bootstrap.Toast($('#liveToastWarning'));
              el = document.getElementById('toast-message-warning')
              if (this.lang === 'en') { el.textContent = "There's an error. Please try again later." }
              else if (this.lang === 'hi') { el.textContent = "कुछ तकनीकी ख़राबी है। कृपया बाद में पुनः प्रयास करें।" }
            }
            
            toast.show()
            
          })
          .catch (err => {
            if (err.response.data.detail === "Invalid token or expired token.") {
              var newTokenSet = this.$store.dispatch('setUserJWT')
              if (newTokenSet) {this.toggleBookmark(bookstate)}
            }
          })
      } else {
        this.$router.push('/login')
      }
    },
    addNote() {
      if (this.isLoggedIn) {
        var toast = new bootstrap.Toast($('#liveToastWarning'));
        var el = document.getElementById('toast-message-warning')
        if (this.lang === 'en') { el.textContent = "Notes feature coming soon!" }
        else if (this.lang === 'hi') { el.textContent = "नोट लेने की सुविधा जल्द ही उपलब्ध होगी!" }
        toast.show()
      } else {
        this.$router.push('/login')
      }
    },
    showCommento() {
      if (this.isLoggedIn) {
        var commento = document.getElementById('commento')
        commento.classList.toggle('d-none')
        // set commento guest name as user name
        document.getElementById('commento-guest-details-input-root').value = this.currentUser.firstName

        this.addReplyEvent()
        // scroll into view
        commento.scrollIntoView()
      } else {
        this.$router.push('/login')
      }
    },
    addReplyEvent() {
      // add it to all visible reply buttons
      const divs = document.querySelectorAll('.commento-option-reply');
      divs.forEach(el => el.addEventListener('click', event => {
        var reply_id = event.target.getAttribute("id").split('-')[3]
        document.getElementById('commento-guest-details-input-' + reply_id).value = this.currentUser.firstName
        document.getElementById('commento-guest-details-input-' + reply_id).classList.add('d-none')
      }));

      // add it to all visible comment buttons
      const btns = document.querySelectorAll('.commento-submit-button');
      btns.forEach(el => el.addEventListener('click', event => {
        // console.log(event)
        event
        this.addReplyEvent()
      }));
    },
    copyText (showToast) {
      this.plausibleEvent('copy')
      /* Get the activity */
      if (this.lang === 'en') {
        var text = this.card.en
        text = text + '\n---\nThis activity comes from Chachi. Get hundreds more at https://chachi.app for free!'
      } else if (this.lang ==='hi') {
        text = this.card.hi
        text = text + '\n---\nयह गतिविधि चाची ऐप से आई है। ऐसी ढेरों और पाइए https://chachi.app पर बिलकुल मुफ़्त!'
      }

      this.copyTextToClipboard(text)

      if (showToast) {
        // give feedback to user
        var toast = new bootstrap.Toast($('#liveToastPrimary'));
        if (this.lang === 'en') { document.getElementById('toast-message-primary').textContent = "Copied text!" }
        else if (this.lang === 'hi') { document.getElementById('toast-message-primary').textContent = "कॉपी कर लिया गया!" }
        toast.show()
      }

      return text
    },
    copyTextToClipboard (text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text)
    },
    fallbackCopyTextToClipboard (text) {
      var textArea = document.createElement('textarea')
      textArea.value = text

      // Avoid scrolling to bottom
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'
        msg
      } catch (err) {
        console.log(err)
      }
      document.body.removeChild(textArea)
    },
    shareToWhatsApp () {
      var text = this.copyText(0)
      window.location.href = 'whatsapp://send?text=' + encodeURI(text)
    },
    shareToTelegram () {
      var text = "\n" + this.copyText(0)
      window.location.href = 'https://t.me/share/url?url=' + encodeURI('https://chachi.app') + '&text=' + encodeURI(text)
    }
  }
}
</script>

<style scoped>
.user-interaction-toolbar {
  height: 8vh;
  border-top: 1px solid rgb(235, 238, 240);
}
.user-interaction-toolbar-link {
  color: rgb(91, 112, 131);
}
#toolbar-nav {
  line-height: 4vh;
  width: 100%;
}
a {
  text-decoration: none;
  color: inherit;
}
.share-menu {
  cursor: pointer;
}
</style>